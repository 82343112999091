
@import '../src/assets/prime-react.scss';
//theme
@import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
@import "primereact/resources/primereact.min.css";
//icons
@import "primeicons/primeicons.css";  
@import url("assets/admin-cutome.scss");

:root {
    --white: #ffffff;
    --black: #000000;
    --primary: #ed4731;
    --success: #379f00;
    --secondary: #384f5c;
    --warning: #ffcc41;
    --error: #ce1500;
    --themebg: #ed4731;
    --themecolor: #ed4731;
}

$white: #ffffff;
$black: #000000;
$primary: #ed4731;
$success: #379f00;
$warning: #384f5c;
$error: #ffcc41;
$themebg: #ed4731;
$themecolor: #ed4731;

@font-face {
    font-family: "SFPRODISPLAYREGULAR";
    src: url("./assets/fonts/SFPRODISPLAYREGULAR.OTF") format("truetype");
}

@font-face {
    font-family: "SFPRODISPLAYMEDIUM";
    src: url("./assets/fonts/SFPRODISPLAYMEDIUM.OTF") format("truetype");
}

@font-face {
    font-family: "SFPRODISPLAYBOLD";
    src: url("./assets/fonts/SFPRODISPLAYBOLD.OTF") format("truetype");
}

* {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}

.auth-bg {
    background: url("./assets/images/structure/auth-bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: top right 0px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .MuiAlert-standard {
        position: absolute;
        top: 10px;
        min-width: 470px;
    }

    .form-bg {
        background: $white;
        padding: 50px;
        border-radius: 24px;
        max-width: 400px;

        .logo-icon {
            display: block;
            text-align: center;
            margin: 0px auto 50px auto;
        }
    }
}

.MuiTypography-root {
    color: $black;
}

//MuiFormControl-root
.MuiFormControl-root {
    width: 100%;
}

.Mui-focused {
    &.MuiFormLabel-root {
        color: $themecolor !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: $themecolor !important;
    }
}

// buttons
.MuiButton-containedPrimary {
    background: $themecolor !important;
}

.MuiButton-textPrimary {
    color: $themecolor !important;
}

// .MuiButton-outlinedPrimary {
//     color: $themecolor !important;
//     border-color: $themecolor !important;
// }

.MuiDialog-paperWidthSm {
    max-width: 440px !important;
    border-radius: 24px !important;

    .MuiDialogContent-root {
        padding: 24px 24px !important;
    }
}

.modal-close {
    width: 14px;
    height: 14px;
    object-fit: contain;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.6;
    cursor: pointer;
}

.MuiDrawer-paperAnchorLeft {
    background-color: #4caf50 !important;

    .MuiListItemIcon-root {
        color: #fff;
    }

    .MuiListItemText-primary {
        color: #fff;
    }
}

.MuiAppBar-positionFixed {
    box-shadow: none !important;
    background-color: #fff !important;
    border-bottom: 1px solid #e8e8e8;
    min-height: 65px !important;

    .MuiIconButton-colorInherit {
        color: #4caf50;
    }
}

.MuiTreeItem-root {
    color: #252525;

    .MuiTreeItem-content {
        padding: 12px 8px;
        box-sizing: border-box;
    }

    .MuiTreeItem-group {
        margin-left: 0 !important;
    }
}

.MuiTreeItem-label {
    color: #252525;
}

.form-group {
    margin-bottom: 24px;
}

.dashboard-parent {
    background: #f9f9ff;
    min-height: 100vh;

    .main-wrapper {
        padding: 83px 20px 20px 20px;

        .MuiAlert-standard {
            position: fixed;
            top: 6px;
            min-width: 470px;
            max-width: 470px !important;
            z-index: 99;
            left: 0;
            right: 0;
            margin: auto;
            justify-content: center;
        }

        .left-side {
            position: sticky;
            top: 85px;
            max-width: 230px;
            margin: auto;
        }

        .height-fix {
            max-height: calc(100vh - 216px);
        }

        .white-box-20 {
            padding: 15px;
            background: $white;
            border-radius: 0.5rem;
        }

        .white-box-20-30 {
            padding: 20px 30px;
            background: $white;
            border-radius: 0.5rem;
        }
    }
}

.owner-detail {
    min-width: 130px;

    .name-text {
        font-size: 16px;
        color: #252525;
        font-weight: 500;
        margin: 0px;

        &.mb-2 {
            margin-bottom: 0.5rem;
        }
    }

    .number-text {
        font-size: 14px;
        margin: 0px;
    }
}

.time-row {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .time-column {
        margin: 4px 0px 4px 0px;
        font-size: 12px;
    }
}

.time-row-working {
    display: flex;
    margin-bottom: 20px;

    .MuiFormControlLabel-root {
        min-width: 120px;
    }
}

.nowrap {
    white-space: nowrap;
}

.address-table {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MuiTableCell-head {
    white-space: nowrap;

    .short-div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .short-box {
            display: flex;
            flex-direction: column;
            margin-left: 4px;

            svg {
                opacity: 0.38;
                height: 12px;
                width: 12px;

                path {
                    transform: scale(1.85);
                    transform-origin: center;
                }

                &:hover {
                    opacity: 1;
                }

                &[data-testid="ArrowDropUpIcon"] {
                    position: relative;
                    bottom: -3px;
                }

                &[data-testid="ArrowDropDownIcon"] {
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    &.MuiTableCell-alignCenter {
        .short-div {
            justify-content: center;
        }
    }
}

.MuiTableCell-root,
.MuiTableCell-head {
    padding: 10px !important;

    .status-drop {
        min-width: 100px;
        padding: 0px;

        .MuiSelect-select {
            padding: 0px !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        display: none;
    }
}

.tag-chips {
    background: #e8e8e8;
    color: #252525;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.round28 {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
}

.round36 {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center;
}

.me-2 {
    margin-right: 0.5rem;
}

.MuiPagination-root {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .Mui-selected,
    .Mui-selected:hover {
        background: #ed4731 !important;
    }
}

.upload-img {
    width: 130px;
    height: 130px;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: auto;
    border: 1px solid #e8e8e8;
    background: #f9f9ff;

    .upload-icon {
        width: 130px;
        height: 130px;
        object-fit: cover;
    }

    .delete-icon {
        color: #e8141b;
        fill: #e8141b;
        position: absolute;
        right: 6px;
        top: 5px;
        cursor: pointer;
    }

    .before-upload {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .upload-icon {
            object-fit: scale-down;
            height: 40px;
        }

        .upload-text {
            color: #4caf50;
            font-size: 11px;
            margin: 4px;
        }

        span {
            font-size: 10px;
            color: #9a99ac;
            line-height: 12px;
            display: inline-block;
            max-width: calc(100% - 20px);
        }
    }

    input[type="file"] {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
}

// .id-img-box {
//     display: flex;
//     align-items: flex-start;
//     justify-content: flex-start;
//     margin-top: 20px;

//     .upload-id {
//         width: 160px;
//         height: 110px;
//         position: relative;
//         border-radius: 0.5rem;
//         background: #F9F9FF;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         text-align: center;

//         .delete-icon {
//             color: #E8141B;
//             fill: #E8141B;
//             position: absolute;
//             right: 6px;
//             top: 5px;
//             cursor: pointer;
//         }

//         .id-img {
//             width: 100%;
//             height: 100%;
//             object-fit: contain;
//         }

//         .upload-icon {
//             width: 20px;
//             height: 20px;
//             object-fit: contain;
//             margin: auto;
//         }

//         .upload-text {
//             color: #4caf50;
//             font-size: 11px;
//             margin: 4px;
//         }

//         span {
//             font-size: 10px;
//             color: #9A99AC;
//             line-height: 12px;
//             display: inline-block;
//             max-width: calc(100% - 20px)
//         }

//         input[type=file] {
//             position: absolute;
//             z-index: 1;
//             left: 0px;
//             top: 0px;
//             width: 100%;
//             height: 100%;
//             cursor: pointer;
//             opacity: 0;
//         }

//     }
// }

.uploads-row {
    display: flex;
    flex-wrap: wrap;

    .upload-multiple {
        width: 130px;
        height: 130px;
        position: relative;
        border-radius: 0.5rem;
        background: #f9f9ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 16px;
        margin-bottom: 16px;

        .delete-icon {
            color: #e8141b;
            fill: #e8141b;
            position: absolute;
            right: 6px;
            top: 5px;
            cursor: pointer;
        }

        .id-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .upload-icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin: 0px;
        }

        .upload-text {
            color: #4caf50;
            font-size: 11px;
            margin: 4px;
        }

        span {
            font-size: 10px;
            color: #9a99ac;
            line-height: 12px;
            display: inline-block;
            max-width: calc(100% - 20px);
        }

        input[type="file"] {
            position: absolute;
            z-index: 1;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }
    }
}

.min-width150 {
    min-width: 150px;
}

.max-width240 {
    max-width: 240px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-width300 {
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MuiSwitch-track {
    background: $primary !important;
}

.Mui-checked {
    color: $primary !important;
}

.switch-row {
    justify-content: space-between;
    margin-left: 0px !important;
    width: 100%;
}

.chips {
    width: auto;
    height: auto;
    padding: 7px 12px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 20px;

    &.chips-active {
        color: #4caf50;
        background: rgb(76 175 80 / 20%);
    }

    &.chips-inactive {
        color: #ff8181;
        background: rgb(255 129 129 / 20%);
    }
}

.MuiMenuItem-root {
    font-size: 14px !important;

    .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 8px;
    }
}

.top-heading-search {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .right-filter {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        .search-box {
            margin-right: 0px;
            &.mr-5{
                padding-right: 20px;
                #outlined-size-small{
                    padding: 10px 16px 10px 0px;
                }
            }
        }
        .status-drop {
            margin-left: 15px;
            min-width: 100px;
        }
        .width160 {
            margin-left: 15px;
        }

        .MuiButtonBase-root.MuiButton-contained {
            margin-left: 15px;
        }
    }
    .width160 {
        max-width: 160px;
    }
}

.outline-category {
    position: relative;
    margin-left: auto !important;
    margin-right: auto !important;

    .delete-category {
        position: absolute;
        right: -36px;
        top: -13px;
        background: #f8bab1;
        color: $primary;
    }

    &::before {
        content: "";
        position: absolute;
        left: auto;
        top: 0px;
        right: -24px;
        bottom: 0px;
        border: 1px dashed #a9acaa;
        border-radius: 0.5rem;
        height: 100%;
        width: calc(100% + 48px);
    }

    .MuiGrid-item {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.add-delete-category {
    border: 1px dashed $primary !important;
    width: 100%;
    position: relative !important;
    margin-top: -24px !important;
    margin-bottom: 24px !important;
}

.bottom-btn-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.table-img-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .round36 {
        margin-right: 10px;
    }
    .square36 {
        margin-right: 10px;
    }
}
.square36 {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    object-fit: cover;
}
.category-box {
    border-radius: 0.5rem;
    border: 1px solid #e8e8e8;
    padding: 15px;

    .category-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;

        .MuiButtonBase-root {
            color: $primary;
        }
    }

    .search-box {
        margin-top: 5px;
        margin-right: 15px;
    }

    .category-content {
        margin-top: 20px;
        min-height: 264px;
        max-height: 264px;
        overflow: auto;

        .category-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0px 0px 20px;
            background: white;
            border-radius: 0.5rem;
            cursor: pointer;

            &:hover,
            &.active {
                background: rgb(237 71 49 / 10%);
            }

            .category-name {
                font-size: 14px;
                color: #252525;
                font-weight: 500;
                margin: 0px;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.MuiFormControlLabel-root {
    .MuiTypography-root {
        font-size: 14px !important;
    }
}

.MuiSvgIcon-root {
    cursor: pointer;
}

// editor
.ck {
    .ck-toolbar {
        border-radius: 0.25rem 0.25rem 0rem 0rem !important;
    }

    .ck-editor__editable_inline {
        border-radius: 0rem 0rem 0.25rem 0.25rem !important;
        min-height: 150px;
        max-height: 200px;
        &.ck-focused {
            border-color: #ed4731 !important;
        }
    }
}
.a-tag{
    color: #ed4731;
    text-decoration: underline;
    cursor: pointer;
}
.MuiCalendarOrClockPicker-root{
    .Mui-selected{
        background-color: #ed4731 !important;
    }
}
.MuiClock-root{
    .MuiClock-pin,.MuiClockPointer-root,.MuiClockPointer-thumb,.MuiClock-amButton,.MuiClock-pmButton{
        background-color: #ed4731 !important;
        border-color: #ed4731 !important;
        color: white !important;
    }


    
}

// tabs
.MuiTabs-vertical{
    border-right: 0 !important;
    border-left: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.MuiTab-root {
    min-width: 100px !important;
}

.MuiTab-textColorPrimary {
    font-size: 14px !important;
    color: #252525 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    align-items: flex-start !important;
}

.MuiTabs-indicator {
    background-color: #ed4731 !important;
    right: unset !important;
    left: 0;
}

.tabs-block {
    .tabs-head {
        background-color: #F7FAF8;
    }
}
.card-white {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid #F7FAF8;
    padding: 20px 30px 30px 30px;
}

.store-detail-head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .h3{
        font-weight: 600;
        font-size: 24px;
        color: #252525;
        margin: 0 12px 0 0;
    }
}
.store-detail-tabs-block{
    margin-top: 40px;
    .card-white{
        padding: 20px 0px 86px 20px;
    }
}
.business-detail-wrap{
    .h6{
        font-weight: 600;
        font-size: 16px;
        color: #252525;
        margin: 0 0 8px 0;
    }
    .p3{
            
        font-size: 12px;
        color: #252525;
        margin: 0 0 0px 0;
    }
}
.signup-right{
    .h3{
        font-weight: 600;
        font-size: 24px;
        color: #252525;
        margin: 0 0 20px 0;
    }
    .p1{
        font-weight: 600;
        font-size: 16px;
        color: #252525;
        margin: 0 0 8px 0;
    }
    .p3{
            
        font-size: 12px;
        color: #252525;
        margin: 0 0 30px 0;
    }
    .img-upload-file{
        background-color: #F7FAF8;
        border-radius: 8px;
        padding: 24px 24px;
        position: relative;
        display: flex;
        margin-bottom: 30px;
        input[type="file"]{
            position: absolute;
            top: -9999999;
            filter: alpha(opacity=0);
            opacity: 0;
            width:0;
            height:0;
            outline: none;
            cursor: pointer;
        }
        .p2{
            font-weight: 600;
            font-size: 14px;
            color: #252525;
            margin: 0 0 4px 0;
        }
        .p3{
                
            font-size: 12px;
            color: #252525;
            margin: 0 0 0px 0;
        }
        .upload-icon{
            width: 32px;
            height: 32px;
            margin-left: 24px;
        }
    }
    .uploaded-image-box{
        background-color: $white;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        padding: 8px 8px;
        width: 80px;
        height: 80px;
        position: relative;
        margin-bottom: 30px;
        .cancel-red{
            position: absolute;
            right: 0px;
            top: 0px;
            cursor: pointer;
        }
    }
    .uploaded-text-block{
        background-color: #FFEEDA;
        border-radius: 8px;
        padding: 6px 40px 6px 12px;
        width: 324px;
        position: relative;
        margin-bottom: 12px;
        &:last-child{
            margin-bottom: 30px;
        }
        .p2{
            font-weight: 600;
            font-size: 14px;
            color: #252525;
        }
        .close-icon{
            position: absolute;
            right: 12px;
            cursor: pointer;
        }
    }
    .checkbox-block{
        display: flex;
        align-items: center;
        .p2{
                
            font-size: 14px;
            color: #252525;
            margin: 0 10px 0 4px;
        }
        
    }
}
.date-picker-btn{
    margin-left: 12px !important;
}
// wallet
.wallet-box-new{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .wallet-icon{
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin: 0px;
    }
    .relative{
        padding:0px 20px;
        position: relative;
        .h4 {
            font-weight: 700;
            font-size: 20px;
            color: #252525;;
            margin: 0px 0px 4px 0px !important;
        }
        .p2{
            margin: 0px;
            font-size: 12px;
            font-weight: 600;
            color: #A9ACAA;
        }

    }        
    .badges {
        padding: 5px 12px;
        border-radius: 0.5rem;
        background-color: #eeeeee;
        font-size: 12px;
        font-weight: 600;
        color: #A9ACAA;
        cursor: pointer;
        &.badges-primary {
            background-color: rgba($primary, $alpha: .10);
            color: $primary;
            
        }
    }
}
.wallet-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px 10px 0px;

    .wallet-row-left {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .money-icon {
            margin-right: 10px;
            width: 40px;
            height: 40px;
        }
    }

    .wallet-row-right {
        text-align: left;
    }
}
.store-detail-head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .h3{
        font-weight: 600;
        font-size: 20px;
        color: #252525;
        margin: 0 12px 0 0;
    }
}
.total-order-text{
    font-weight: 400;
    font-size: 14px;
    color: #252525;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    display: flex;
    align-items: center;

}
.date {
    font-size: 12px;
    font-weight: 600;
    color: #A9ACAA;
    margin: 12px 0 0px 0;
    display: flex;
}
.error{
    color: red !important;
    font-size: 14px;
    text-transform: capitalize;
}

h1,h2,h3,h4,h5,h6,p,input,textarea,select,checkbox,table,tr,td,th,button,form,li{
    font-family: SFPRODISPLAYREGULAR !important;
}
.maxwidth-600 {
    .MuiDialog-paperWidthSm {
        width: 100% !important;
        max-width: 600px !important;
        border-radius: 10px !important;
    }
}
.main-modal {
    .h4 {
        color: #252525;
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 28px;
    }
}
.uom-content{
    max-height: calc(100vh - 150px);
    padding: 10px 0px 60px 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .switch-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-bottom: 20px;
        .h6 {
            margin: 0px 0px;
        }
        .MuiSwitch-root {
            margin: 0 28px 0px 28px;
        }
        .p4{
            font-size: 12px;
        }
    }
    .h6{
        font-weight: 600;
        font-size: 16px;
        color: #252525;
        margin: 0px 0px 8px 0px;
    }
    .p3{
        font-weight: 400;
        font-size: 12px;
        color: #252525;
        margin: 0px 0px 20px 0px !important;
    }
    .uploads-row {
        display: flex;
        flex-wrap: wrap;    
        &>div{
            width: 106px;
            @media (max-width: 899px) {
                width: 106px;
            }
            @media (max-width: 599px) {
                width: 90px;
            }
        }
        .upload-multiple {
            width: 96px;
            height: 96px;
            position: relative;
            border-radius: 0.5rem;
            background: #F7FAF8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: 0px;
            overflow: hidden;
            cursor: pointer;
            @media (max-width: 899px) {
                width: 90px;
                height: 90px;
                margin-left: 0px;
            }
            @media (max-width: 599px) {
                width: 80px;
            }
            .ant-upload-wrapper{
                width: 100%;
                height: 100%;
                .ant-upload{
                    width: 100%;
                    height: 100%;
                    .ant-upload{
                        flex-direction: column;
                    }
                }
            }

            .delete-icon {
                color: #E8141B;
                fill: #E8141B;
                position: absolute;
                right: 6px;
                top: 5px;
                cursor: pointer;
                z-index: 5;
            }

            .id-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .upload-icon {
                width: 32px;
                height: 32px;
                object-fit: contain;
                margin: 0px;
            }

            .upload-text {
                font-weight: 600;
                font-size: 12px;
                color: #252525;
                margin-top: 8px;
                margin-bottom: 0px;
            }
            .required-text{
                font-weight: 400;
                font-size: 10px;
                color: #252525;
                max-width: 65%;
                position: relative;
                margin-bottom: -20px;
                margin-top: -2px;
                line-height: 13px;
            }

            input[type=file] {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }

            .progress-img{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                width: calc(100% - 0px);
                margin: auto;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-color: rgba(#fff, .75);
                .progress-count{
                    color: #252525;
                    font-size: 24px;
                    font-weight: 600;
                    display: block;
                    margin-bottom: 8px;
                }
                .progress-box{
                    height: 6px;
                    width: calc(100% - 10px);
                    border-radius: 6px;
                    overflow: hidden;
                    background: #FFEEDA;
                    margin: 3px auto;
                    position: relative;
                    .progress-content{
                        position: absolute;                            
                        left: 0px;
                        right: auto;
                        height: 6px;
                        width: auto;
                        background: $primary;
                        border-radius: 6px;
                    }
                }
            }
        }

        .p2 {
            font-weight: 700;
            font-size: 12px;
            color: #252525;
        }
    }
}
.uom-footer{
    padding: 12px 0px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 45px);
    background: #fff;
    .MuiButtonBase-root.MuiCheckbox-root{
        margin: 0px 8px;
    }
    .min-width140{
        min-width: 140px;
    }
}
.required-star {
    color: #ed4731;
}

// starting vinay css MUI
//MuiInputAdornment-positionEnd
.MuiInputAdornment-positionEnd.bg-pink {
    height: 100%;
    background: #FFF8F3;
    position: absolute;
    padding: 10px 16px;
    top: 1px;
    bottom: auto;
    border-radius: 0px 8px 8px 0px;
    margin-left: 0px;
    min-height: 54px;
    right: 1px;
    left: auto;
    border-left: 1px solid #A9ACAA;

    .MuiTypography-root {
        font-size: 14px;
        color: #252525;
    }
    .MuiSelect-select{
        padding: 0px  20px 0px 0px !important;
    }
    .MuiOutlinedInput-notchedOutline{
        border: none !important;
    }
    .MuiSelect-iconOutlined {
        left: auto;
        right: -8px !important;
    }
}
.adject-select{
    margin-left: 24rem;
    position: relative;
    bottom: 1rem;
    width: 22.3rem;
}
.center-loading{
    position: absolute;
    left: 40%;
    bottom: 40%;
}

.form-group{
    margin-bottom: 20px;
    display: block;
    position: relative;
}
.w-full-height-56{
    width: 100%;
    height: 56px;
}

.edit-new-icon{
    background: #de2819;
    /* padding: 10px; */
    color: #fff;
    width: 31px;
    color: #FFF!important;
    text-align: center;
    font-size: 1px;
    display: flex;
    justify-content: center;
    height: 31px;
    top:4px;
    position: absolute;
    right: 2px;
    border-radius: 100px; 
}

.edit-new-icon .delete-icon{
    position: unset;
    color:#FFF!important;
    fill:#fff!important;
    margin-top: 2px;
}

.time-error{
    margin-bottom: 18px;
    text-align: right;
}

.loader-open{
    pointer-events: none;
}

.raing-50{
    width: 50%;
}

.ant-modal-wrap.img-crop-modal{
    z-index: 99999 !important;
}
change-password-btn{
    border: 1px solid black;
}
.active{
    background-color: #e8141b;
    color: white;
}
.modal-arabic-unit {
    .MuiInputLabel-root {
        transform: translate(14px, -9px) scale(0.75) !important;
    }
    legend {
        width: auto !important;
        max-width: 100% !important;
        transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms !important;
    }
}