/* Table container */
.table-container {
    overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  }
  
   
  
  /* Responsive styles using media queries */
  @media screen and (max-width: 768px) {
    /* Adjust table layout for smaller screens */
    .table-container {
      overflow-x: auto;
    }
    .table {
      font-size: 14px; /* Decrease font size for smaller screens */
    }
    .thead-dark th,
    td {
      padding: 8px !important; /* Decrease padding for smaller screens */
    }
  }
  .chips .bg-gray {
    background: #a9acaa;
    color: #fff;
}
  .chips {
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    margin: 0 4px;
    padding: 4px 8px;
}

tbody, td, tfoot, th, thead, tr {
    border-color: #8080801f!important;
    border-bottom-style: solid!important;
    border-width: 0!important;
}
  .normal_text{
    font-size: 12px!important;
    margin: 2px
  }
  .bigger_text{
    font-size: 14px!important;
    text-transform: capitalize;
  }
  .capital{
    text-transform: capitalize;
  }
  .flex_class{
    display:flex
  }
  @media screen and (max-width: 576px) {
    /* Further adjust table layout for even smaller screens */
    .table {
      font-size: 12px; /* Further decrease font size */
    }
  }
  