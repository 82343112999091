  .green-row {
      background: #cef5a1 !important;
     
    }
    .yellow-row {
      background: lightblue;
    }
    .radio-options {
      display: flex;
      margin: 20px 0px;
    }
 