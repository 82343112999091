/* styles.css */

/* Order Summary Component */
.order-summary-container {
    display: flex;
    justify-content: center;
  }
  
  .order-summary-box {
    border: 4px solid orange;
    border-radius: 10px;
    padding: 10px;
  }
  
  .order-id {
    background: #fba82f;
    display: inline-block;
    font-size: 16px;
    font-weight: 900;
    padding: 10px;
    border-radius: 8px;
    margin: 0;
  }
  
  .summary-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .summary-item {
    flex: 0 0 25%;
    margin-top: 20px;
    text-align: center;
  }
  
  .summary-title {
    color: #fba82f;
    border-style: dotted;
    font-weight: 900;
  }
  
  .summary-count {
    background: #fba82f;
    border-radius: 0 0 14px 14px;
  }
  
  /* Order Date Customer Note Component */
  .order-date-customer-note-container {
    display: flex;
    justify-content: center;
  }
  
  .order-date-customer-note-box {
    border: 4px solid orange;
    border-radius: 10px;
    padding: 10px;
  }
  
  .date-title,
  .customer-title,
  .note-title {
    margin: 0;
    font-weight: 100;
  }
  
  .date,
  .customer,
  .note {
    color: red;
  }
  
  
  .chips {
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    margin: 0 4px;
    padding: 4px 8px;
}
  .normal_text{
    font-size: 12px!important;
    margin: 2px
  }
  .bigger_text{
    font-size: 14px!important;
    text-transform: capitalize;
  }
  .flex_class{
    display:flex!important
  }
  /* Picker Info Avatar Component */
  .picker-info-avatar-container {
    display: flex;
    justify-content: center;
  }
  
  .picker-info-avatar-box {
    border: 4px solid orange;
    border-radius: 10px;
    padding: 10px;
    display: flex;
  }
  
  .info {
    flex: 1;
  }
  
  .greeting {
    color: orange;
    font-weight: bold;
  }
  
  .details {
    display: flex;
    align-items: center;
  }
  