.order-listing-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            margin: 0 12px 0 0;
            font-weight: 700;
            font-size: 24px;
            color: #252525;
        }
    }
}
.new-dot{
    position: relative;
    &::after{
        content: '';
        position: absolute;
        left:22px;
        right: auto;
        top: 0px;
        bottom: 0px;
        margin: auto;
        width: 6px;
        height: 6px;
        background: #ed4731;
        border-radius: 50%;
    }
}
.table-type-parent{
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    &.red-border{
        border: 1px solid #ed4731;
        cursor: pointer;
    }
    &.gray-border{
        border: 1px solid #e8e8e8;
        cursor: pointer;
    }
    .table-type-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 10px 4px;
        .table-type-td{
            padding: 0px 12px;
            &.width180 {
                width: 180px;
            }
            &.flex{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .type-td{
                    // width: 240px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; 
                    display: block;
                }
            }
            .icon24{
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
            .type-th{
                font-weight: 500;
                font-size: 12px;
                color: #A9ACAA;
                margin: 0;  
                line-height: 14px;
            }
            .type-td{
                font-weight: 600;
                font-size: 14px;
                color: #252525;
                margin: 3px 0px;
                line-height: 16px;
                display: flex;
                align-items: center;
                &.width120{
                    width: 120px;
                    word-break: break-word;
                }
                .icon20{
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                }
            }
            .relative{
                position: relative;
                padding: 0px 12px;
            }
        }
    }
    .table-type-body{
        border-top: 1px solid #eeeeee;
        padding: 20px 20px 20px 20px;
        .table-type-body-inner-flex{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .h6{
                
                font-weight: 700;
                font-size: 16px;
                color: #252525;
                font-size: 12px !important;
                margin: 0;
                &.mb-4{
                    margin-bottom: 16px;
                }
                &.gray{
                    color: #A9ACAA;
                }
            }
            .left-detail-box{
                width: 100%;
                padding-left: 0px;
                padding-right: 20px;
                .product-table{
                    margin-top: 8px;
                    .MuiTableRow-root{
                        &:last-child{
                            .MuiTableCell-root{
                                border-bottom: 0px;
                            }
                        }
                    }
                    .MuiTableCell-root{
                        padding: 8px 0px !important;
                        font-weight: 400;
                        font-size: 14px;
                        color: #252525;
                        &.MuiTableCell-alignRight{
                            text-align: right !important;
                        }
                        .flex{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .img32{
                                width: 32px;
                                height: 32px;
                                object-fit: contain;
                                border-radius: 8px;
                            }
                            .product-title{
                                font-weight: 400;
                                font-size: 14px;
                                color: #252525;
                                line-height: 16px;
                                padding: 0px 16px;
                            }
                        }
                        .gray-through{
                            font-weight: 400;
                            font-size: 12px;
                            color: #A9ACAA; 
                            text-decoration: line-through;
                            
                        }
                    }
                }
                .order-tracking-box{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    position: relative;
                    margin-top: 20px;   
                    .order-status{
                        width: 130px;
                        position: relative;
                        padding-top: 12px;
                        &::after{
                            content: '';
                            position: absolute;
                            left: 0px;
                            right: 0px;     
                            top: 0px;
                            bottom: auto;
                            height: 1px;
                            width: 130px;
                            background: #eeeeee;
                        }
                        &::before{
                            content: '';
                            position: absolute;
                            left: 0px;
                            right: auto;     
                            top: -6px;
                            bottom: auto;
                            height: 12px;
                            width: 12px;
                            background: #ffffff;
                            border-radius: 50%;
                            border: 1px solid #eeeeee;
                            z-index: 5;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                        &.done{
                            &::after{
                                background: #1EA896;
                                height: 2px;
                            }
                            &::before{
                                background: #1EA896;
                                border: 1px solid #1EA896;
                            }
                        }
                        &.active{
                            &::before{
                                background: #1EA896;
                                border: 1px solid #1EA896;
                            }
                        }
                        p.p2{
                            font-weight: 400;
                            font-size: 14px;
                            color: #252525;
                            margin: 0px 0px 4px 0px;
                        }
                        p.p4{
                            font-weight: 400;
                            font-size: 10px;
                            color: #252525;
                            margin: 0px;
                        }
                    }
                }
                .MuiRating-root{
                    font-size: 30px;
                    margin: 20px 0px 12px 0px;
                }
                .p2{
                    font-weight: 400;
                    font-size: 14px;
                    color: #252525;
                    margin: 0px;
                }
            }
            .right-detail-box{
                min-width: 290px;
                width: 290px;
                position: relative;
                padding-left: 20px;
                padding-right: 0px;
                padding-bottom: 20px;
                border-left: 1px solid #eeeeee;
                .MuiDivider-root{
                    margin: 6px 0px;
                    position: relative;
                    border-color: #eeeeee;
                    &.my-5{
                        margin: 20px 0px;
                    }
                }
                .payment-value{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 0px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #252525;
                }
                .payment-value-total{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 0px 15px 0px;
                    .p2{
                        font-weight: 700;
                        font-size: 14px;
                        color: #252525;
                    }
                }
                .btn-row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0px -5px;
                    .mx-5{
                        margin: 0px 5px !important;
                    }
                }
                .pink-box{
                    background: #FFF8F3;
                    border-radius: 8px;
                    padding: 12px 16px;
                    margin: 8px 0px 20px 0px;
                    .p2{
                        font-weight: 400;
                        font-size: 14px;
                        color: #252525;
                        margin: 2px 0px;
                    }
                    .p2-bold{
                        font-weight: 700;
                        font-size: 14px;
                        color: #252525;
                    }
                    .p3{
                        font-weight: 500;
                        font-size: 14px;
                        color: #A9ACAA;
                        margin: 10px 0px 4px 0px;
                    }
                }
                .mb-4{
                    margin-bottom: 14px;
                }
                .p3{
                    font-weight: 500;
                    font-size: 12px;
                    color: #A9ACAA;
                    margin: 0px 0px 4px 0px;
                    &.semi{
                        font-weight: 600;
                    }
                }
                .p2{
                    font-weight: 400;
                    font-size: 14px;
                    color: #252525;
                    margin: 0;
                }
            }
        }
    }
}
// Red dot in tabs
.new-dot::after {
    content: "";
    position: absolute;
    right: 22px;
    left: auto;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 6px;
    height: 6px;
    background: #ED4731;
    border-radius: 50%;
}
// btns
.secondary-gray{
    background-color: #A9ACAA !important;
}
.secondary{
    background-color: #1EA896 !important;
}
.secondary-outline-gray{
    color: var(--gray) !important;
    background-color: #fff !important;
    border: 1px solid #A9ACAA !important;
}
.clear-all {
    padding: 10px;
    color: #1EA896;
    cursor: pointer;
}
.mr-20{
    margin-right: 20px;
}
.w-500 {
    width: 500px;
}