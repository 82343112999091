// dropdown
.p-dropdown {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 4px;
    &:not(.p-disabled) {
        &.p-focus{
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: #ed4731;
        }
        &:hover {
            border-color: #000;
        }
    }
}
.p-inputtext {
    padding: 8.5px 0.75rem !important;
    color: #000 !important;
    &:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: #ed4731;
    }
}
.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        .p-dropdown-items{
            .p-dropdown-item{
                color: #000 !important;
                .flag {
                    display: none !important;
                }
            }
        }
    }
}
.p-dropdown-label{
    .flag {
        display: none !important;
    }
}
.maxwidth-430{
    .MuiDialogContent-root{    max-width: 430px;
    min-width: 430px;
}

}
.before-upload{
    img.upload-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
    }
}

.download-invoice{
    cursor: pointer;
}
.upload-img-parent{
    position: relative;
    .error{
        position: absolute;
        bottom: -20px;
    }
}
.overflow-auto-custome{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #3c4b64 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #3c4b64;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}
.new-modal-hed{
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}
.mt-4{
    margin-top: 16px;
}

.mb-3{
    margin-bottom: 12px;
}
.label-row{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .label-left{
        min-width: 100px;
        position: relative;
        display:block;
        margin-bottom: 4px;
        font-size: 14px;
    }
    .label-right{
        margin-bottom: 4px;
        position: relative;
        font-size: 14px;
    }
}
.note-text{
    padding: 8px;
    background-color: #f7ece4;
    margin-top: 20px;
    border-radius: 0.25rem;
    font-size: 12px;
}

.pay_info{
    font-size: 12px;
    color:#666;
    text-decoration: underline;
    cursor: pointer;
}

.driver-rating .MuiRating-label{
   top:4px;
}
.white-box-new-shadow{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px #00000045;
    padding: 10px;
    img{
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 12px;
        border-radius: 1px solid #666;
        object-fit: cover;
    }
    .relative{
        .h6{
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin-bottom: 4px;
        }
        .p2{
            font-size: 12px;
            color: #3c4b64;
            margin-bottom: 0px;
        }
    }
}
